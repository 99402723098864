import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
    PageHeader,
    TechnologyPartners,
    BrandsWorkedWithUs
} from '@components';
import {
    BetterThanOther,
    MultiColumnList,
    MultiColumnContent
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class RealEstate extends Component {
    render() {
        const realEstate = pageContent.subPage.realEstate;
        
        return (
            <Layout>
                <PageHeader image={realEstate.headerImage} />

                <Heading
                    title={realEstate.title}
                    overview={realEstate.overview}
                    noHeadingIcon={true} />

                <BetterThanOther content={realEstate.betterThanOther} />

                <MultiColumnContent content={realEstate.promises} />

                <MultiColumnContent content={realEstate.stakeholders} />

                <MultiColumnList content={realEstate.solutions} />

                <PageNavigation
                    prev={pageContent.navigation.prev}
                    next={pageContent.navigation.next} />

                <TechnologyPartners />

                <BrandsWorkedWithUs />

            </Layout>
        );
    }
}

export default RealEstate;
